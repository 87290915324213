/*@tailwind base;*/
/*@tailwind components;*/
/*@tailwind utilities;*/

@import 'exhishare/main';
@import 'primevue/resources/themes/tailwind-light/theme.css';
@import 'primevue/resources/primevue.css';
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';

/* from <https://stackoverflow.com/a/66815250> */
body {
	margin: 0;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: var(--surface-a);
	font-family: var(--font-family);
	font-weight: 400;
	color: var(--text-color);
}
